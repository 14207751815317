import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./Pages/Home"
import Nosotros from './Pages/Nosotros';
import Nav from './Components/Nav';
import Proyectos from './Pages/Proyectos';
import Contacto from './Pages/Contacto';
import Footer from './Components/Footer';
import Blog from './Pages/Blog';
import ProyectoTameplate from './Pages/ProyectoTameplate';
import BlogTameplate from './Pages/BlogTameplate';
import Cat_ProyectosEnDesarrollo from './Pages/Cat_ProyectosEnDesarrollo';
import Pescadores from './Pages/ProyectosEnDesarrollo/Pescadores';

function App() {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/proyectos" element={<Proyectos />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/proyecto/palmas14" element={<ProyectoTameplate />} />
        <Route path="/blogtameplate" element={<BlogTameplate />} />
        <Route path="/proyectos-en-desarrollo" element={<Cat_ProyectosEnDesarrollo />} />
        <Route path="/proyectos-en-desarrollo/pescadores" element={<Pescadores />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;