import "../App.css"
import Logo from "../Img/ALFONSO-logo.svg"
import PescadoresThumbail from "../Img/Proyectos/Proyectos en desarrollo/Pescadores/65d5078fadfb9ddaf7a6b797_Edificio A  01.jpg"
import SayulitaRevolucionThumbail from "../Img/Proyectos/Proyectos en desarrollo/Sayulita Revolución/65d5056bcf8d6badd08f1661_CASA 03.jpg"
import BuceriasZaragozaThumbail from "../Img/Proyectos/Proyectos en desarrollo/BuceriasZaragoza/65d5037f39c94eee8918fcb0_10 Nocturna.png"

function Cat_ProyectosEnDesarrollo() {
    return (
        <>

            <div class="container py-5">
                <div class="row align-items-center gy-4 gx-0">
                    <div class="col-6 col-md-4">
                        <a href="proyectos" className="lh-0"><i class="bi bi-arrow-left me-2"></i>Proyectos</a>
                    </div>
                    <div class="col-12 col-md-4 text-center">
                        <h1 className="mt-0">En desarrollo</h1>
                    </div>
                </div>
            </div>

            <div class="container justify-content-center align-items-center">
                <div class="row gx-4 gy-4">

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="proyectos-en-desarrollo/pescadores">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={PescadoresThumbail} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Pescadores</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="proyectos-en-desarrollo/pescadores">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={SayulitaRevolucionThumbail} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Sayulita Revolución</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="proyectos-en-desarrollo/pescadores">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={BuceriasZaragozaThumbail} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Departamentos Bucerias Zaragoza</h5>
                                </div>
                            </div>
                        </a>
                    </div>



                </div>
            </div>


        </>
    );
}

export default Cat_ProyectosEnDesarrollo;
