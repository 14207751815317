import "../../App.css"
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import Image1 from "../../Img/Proyectos/Proyectos en desarrollo/Pescadores/65d5078fadfb9ddaf7a6b797_Edificio A  01.jpg"
import Image2 from "../../Img/Proyectos/Proyectos en desarrollo/Pescadores/65d5078bcddc61efbc399cd8_Conjunto 03.jpg"

function Pescadores() {


    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    return (
        <>
            <div class="container py-5">
                <div class="row align-items-center gy-4 gx-0">
                    <div class="col-6 col-md-4">
                        <a href="proyectos" className="lh-0"><i class="bi bi-arrow-left me-2"></i>Proyectos</a>
                    </div>
                    <div class="col-12 col-md-4 text-center">
                        <h1 className="mt-0">Pescadores</h1>
                    </div>
                </div>
            </div>

            <FsLightbox
                toggler={lightboxController.toggler}
                sources={[
                    require('../../Img/Proyectos/Proyectos en desarrollo/Pescadores/65d5078fadfb9ddaf7a6b797_Edificio A  01.jpg'),
                    require('../../Img/Proyectos/Proyectos en desarrollo/Pescadores/65d5078bcddc61efbc399cd8_Conjunto 03.jpg'),
                    "https://assets-global.website-files.com/619e933166d45d327c877b79/65d4cf23165cf9daaa953e65_Esencia_457.jpg",
                    "https://assets-global.website-files.com/619e933166d45d327c877b79/61cd32528cdf721e2c508f66_Esencia_197.jpg"

                ]}
                slide={lightboxController.slide}
            />

            <div className="container-fluid position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={Image1} className="w-100" alt="..." height={650} />
            </div>

            <div className="container-fluid">

                <div className="row gy-3 gx-3 mt-1">
                    
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={Image2}
                            className="w-100 h-100"
                            alt="Wintry Mountain Landscape"
                            type="button"
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src="https://assets-global.website-files.com/619e933166d45d327c877b79/65d4cf23165cf9daaa953e65_Esencia_457.jpg"
                            className="w-100 h-100"
                            alt="Wintry Mountain Landscape"
                            type="button"
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src="https://assets-global.website-files.com/619e933166d45d327c877b79/61cd32528cdf721e2c508f66_Esencia_197.jpg"
                            className="w-100 h-100"
                            alt="Wintry Mountain Landscape"
                            type="button"
                        />
                    </div>
                    
                </div>
            </div>

        </>
    );
}

export default Pescadores;