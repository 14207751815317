import "../App.css"
import Logo from "../Img/ALFONSO-logo.svg"

function BlogTameplate() {
    return (
        <>

            <div class="container col-12 col-md-8 mx-a">
                <div class="row gy-4 gx-4">
                    <div class="col-12 col-md-6 mx-auto">
                        <form>
                            <div class="row">
                                <div class="col">
                                    <label for="exampleInputEmail1" class="form-label">Nombre</label>
                                    <input type="text" class="form-control" placeholder="" aria-label="First name" />
                                </div>
                                <div class="col">
                                    <label for="exampleInputEmail1" class="form-label">Apellido</label>
                                    <input type="text" class="form-control" placeholder="" aria-label="Last name" />
                                </div>
                                <div class="mt-3 mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Correo electrónico</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Teléfono</label>
                                    <input type="tel" class="form-control" id="exampleInputPassword1" />
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Mensaje</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Tu mensaje"></textarea>
                                </div>
                                <div class="d-grid gap-2 mt-2">
                                    <button type="submit" class="btn btn-outline-dark rounded-0 btn-lg">Enviar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>





            <section className="mt-4">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d119448.14366202583!2d-103.37463!3d20.679565!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae116ab3d501%3A0x9002f49f8ef59d42!2sAmado%20Nervo%20154%2C%20Ladr%C3%B3n%20de%20Guevara%2C%20Ladron%20De%20Guevara%2C%2044600%20Guadalajara%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1716216569692!5m2!1ses-419!2smx" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </>
    );
}

export default BlogTameplate;
