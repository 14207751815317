import "../App.css"


function Proyectos() {


    return (
        <>

<section>
        <div className="container text-center col-md-12">
          <h1 className="mb-4 lh-1 py-5">Proyectos</h1>

          <div class="row row-cols-1 row-cols-md-3 gy-3">
            <div class="col">
              <div class="card h-100">
                <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/65d5079e0aab4065d55c764a_Edificio%20B%20%2001.jpg" class="card-img-top h300" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">En desarrollo</h5>
                  <a href="proyectos-en-desarrollo" type="submit" class="btn btn-outline-dark rounded-0 btn-lg  mt-3">Ver más</a>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61ce68257d74a77ce28d10e1_8.jpg" class="card-img-top h300" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Casas de descanso</h5>
                  <button type="submit" class="btn btn-outline-dark rounded-0 btn-lg  mt-3">Ver más</button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61ce084e42c759712fd5cd06_Casa5.10.jpg" class="card-img-top h300" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Residenciales</h5>
                  <button type="submit" class="btn btn-outline-dark rounded-0 btn-lg  mt-3">Ver más</button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61cd32902ef0ae4b09fbc2ab_Esencia_233.jpg" class="card-img-top h300" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Turismo</h5>
                  <button type="submit" class="btn btn-outline-dark rounded-0 btn-lg  mt-3">Ver más</button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61cd244bcaa266a740b59baa_Oficinas.IMEC.9.JPG" class="card-img-top h300" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Insustrial y comercial</h5>
                  <button type="submit" class="btn btn-outline-dark rounded-0 btn-lg  mt-3">Ver más</button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section >

        </>
    );
}

export default Proyectos;
