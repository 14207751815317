import "../App.css"
import Formulario from "../Components/Formulario";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <div className="container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="2000">
              <img src="https://assets-global.website-files.com/619e933166d45de9c8877b50/61cb730a39c94d781d92ffe0_slide3_alfonso_2.jpeg" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61ce062d8d36f1ba32b8fa15_1.JPG" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src="https://assets-global.website-files.com/619e933166d45de9c8877b50/61cb83252e259d638ab8893b_casa_alberca_2.jpeg" class="d-block w-100" alt="..." />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div className="container col-12 col-sm-12 col-md-10 col-lg-12 col-xl-9">

        <div className="row align-items-top py-5">

          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="card h-100">
              <h1 className="fw-medium">Arquitectura atemporal y de vanguardia.</h1>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="card h-100 rounded-0">
              <h6 className="lh-base">Con más de 25 años de experiencia en el desarrollo de proyectos arquitectónicos. Alfonso Núñez se destaca por su capacidad de diseñar y crear espacios con un estilo vanguardista, moderno y atemporal.</h6>
              <a href="/nosotros" type="button" class="btn btn-outline-dark rounded-0 col-12 col-md-3 mt-2 btn-lg">Leer más</a>
              <Link to="/nosotros" type="button" class="btn btn-outline-dark rounded-0 col-12 col-md-3 mt-2 btn-lg">Leer más</Link>
            </div>
          </div>
        </div>

      </div>


      <section className="img-bg-comienza min-vh-100 d-flex flex-column justify-content-center align-items-center position-relative">
        <div className="overlay2 position-absolute w-100 h-100"></div>
        <div className="container text-center position-relative col-md-5">
          <h1 className="text-white mb-4 lh-1">Damos vida a tus ideas y sueños, creando espacios únicos y de calidad</h1>
          <a type="button" href="/contacto" className="btn btn-light rounded-0 btn-lg">Hablemos de tu proyecto</a>
        </div>
      </section>

      <section>
        <div className="container text-center col-md-12">
          <h1 className="mb-4 lh-1 py-5">Proyectos</h1>

          <div class="row row-cols-1 row-cols-md-3 gy-3">
            <div class="col">
              <div class="card h-100">
                <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/65d5079e0aab4065d55c764a_Edificio%20B%20%2001.jpg" class="card-img-top h300" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">En desarrollo</h5>
                  <a href="proyectos-en-desarrollo" type="submit" class="btn btn-outline-dark rounded-0 btn-lg  mt-3">Ver más</a>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61ce68257d74a77ce28d10e1_8.jpg" class="card-img-top h300" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Casas de descanso</h5>
                  <button type="submit" class="btn btn-outline-dark rounded-0 btn-lg  mt-3">Ver más</button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61ce084e42c759712fd5cd06_Casa5.10.jpg" class="card-img-top h300" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Residenciales</h5>
                  <button type="submit" class="btn btn-outline-dark rounded-0 btn-lg  mt-3">Ver más</button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61cd32902ef0ae4b09fbc2ab_Esencia_233.jpg" class="card-img-top h300" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Turismo</h5>
                  <button type="submit" class="btn btn-outline-dark rounded-0 btn-lg  mt-3">Ver más</button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61cd244bcaa266a740b59baa_Oficinas.IMEC.9.JPG" class="card-img-top h300" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Insustrial y comercial</h5>
                  <button type="submit" class="btn btn-outline-dark rounded-0 btn-lg  mt-3">Ver más</button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section >

      <div class="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-4 py-5 mx-auto">
        <h3 className=" fw-semibold text-uppercase ls-2px small text-center">Dejanos tus datos</h3>
        <h6 className=" fw-semibold text-uppercase ls-2px small mb-5 text-center">Y te contactaremos</h6>
        <Formulario />
      </div>







    </>
  );
}

export default Home;
