import React from 'react';
import "../App.css"
import Formulario from "../Components/Formulario";

function Contacto() {
    return (
        <>

            <section className="bg-wallpaper-contacto">
                <div class="container col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 mx-auto">
                    <div class="row gy-4 gx-4">
                        <div class="col-12 col-md-12 bg-yellow">
                            <h6 className=" fw-semibold text-uppercase ls-2px small">Contacto</h6>
                            <div className="mt-4">
                                <h6 className="lh-base">
                                    Amado Nervo 154 Col. Ladrón de Guevara<br />
                                    Guadalajara, Jalisco. México.
                                </h6>
                                <h6 className="lh-base">
                                    +52 33 3100 2476
                                </h6>
                                <h6 className="lh-base">
                                    contacto@alfonsonunez.net
                                </h6>
                            </div>
                        </div>
                        <div class="col-12 col-md-12">
                            <h6 className=" fw-semibold text-uppercase ls-2px small mb-5">O dejanos tus datos</h6>
                            <Formulario />
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-4">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d119448.14366202583!2d-103.37463!3d20.679565!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae116ab3d501%3A0x9002f49f8ef59d42!2sAmado%20Nervo%20154%2C%20Ladr%C3%B3n%20de%20Guevara%2C%20Ladron%20De%20Guevara%2C%2044600%20Guadalajara%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1716216569692!5m2!1ses-419!2smx" width="100%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </>
    );
}

export default Contacto;
