import "../App.css"
import fotoA from "../Img/alfonso.png"
import PlanoFoto from "../Img/plano3.svg"
import Foto from "../Img/equipo-vertice-estudio.png"


function Nosotros() {
    return (
        <>

            <section>
                <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 mx-auto">
                    <h6 className="text-uppercase small ls-2px fw-semibold opacity-50 text-center">Estudio de Arquitectura</h6>
                    <h1 className="fw-bolder lh-sm text-center">Nos avalan más de 25 años de experiencia</h1>

                    <div className="mt-4">
                        <img src={Foto} className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-6 mx-auto">
                        <div className="row text mt-3 opacity-75">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-12">
                                <h6 className="lh-base text-justify">Nos llena de satisfacción ayudar a nuestros clientes a hacer realidad sus sueños, colaborando con entusiasmo en cada desafío, por más complejo que sea el proyecto.</h6>
                                <h6 className="lh-base mt-3 text-justify">Nos encargamos de todo el proceso, desde encontrar el lugar perfecto hasta supervisar la construcción, trabajando estrechamente con el contratista sugerido. Nos dedicamos exclusivamente al diseño arquitectónico, tanto estético como funcional.</h6>
                                <h6 className="lh-base mt-3 text-justify">Nos preocupamos por interactuar personalmente con los futuros residentes para entender sus necesidades, centrándonos en diseñar una solución personalizada y enriquecedora en todos los aspectos, como el espacio y la psicología.</h6>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-12">

                                <h6 className="lh-base text-justify">Nos hemos especializado en arquitectura residencial, considerándola la forma más comprometedora y digna de expresión para un arquitecto. Aunque también hemos trabajado en una variedad de proyectos, desde residenciales hasta industriales, hotelería y comerciales. Destaca nuestra experiencia en arquitectura de playa, donde fusionamos el habitar con el arte de convivir con la naturaleza.</h6>
                                <h6 className="lh-base mt-3 text-justify">Buscamos que nuestras propuestas sean altamente funcionales y promuevan una relación armoniosa entre los ocupantes, enriqueciendo sus vidas en el proceso.</h6>
                                <h6 className="lh-base mt-3 text-justify">Nuestra trayectoria incluye la realización de asesorías en proyectos realizados por otros arquitectos, con el objetivo de enriquecer la propuesta sin alterar el desarrollo ni el concepto original del proyecto.</h6>
                            </div>
                        </div>
                    </div>

                </div>

            </section>




        </>
    );
}

export default Nosotros;
