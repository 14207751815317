import "../App.css"

function Footer() {
    return (
        <>
            <section className="py-5">
                <div class="container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mx-auto">
                    <footer>
                        <div class="row gy-4">
                            <div class="col-6 col-md-4">
                                <h6 className="fw-semibold p-0 small">Mapa del sitio</h6>
                                <ul class="nav flex-column custom-footer-list">
                                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Inicio</a></li>
                                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Conócenos</a></li>
                                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Proyectos</a></li>
                                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Blog</a></li>
                                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Contacto</a></li>
                                </ul>
                            </div>

                            <div class="col-6 col-md-4">
                                <h6 className="fw-semibold small">Proyectos</h6>
                                <ul class="nav flex-column custom-footer-list">
                                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">En desarrollo</a></li>
                                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Casas de descanso</a></li>
                                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Residencial</a></li>
                                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Turismo</a></li>
                                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Industrial y comercial</a></li>
                                </ul>
                            </div>                            

                            <div class="col-12 col-md-4">
                                <h6 className="fw-semibold small">Contacto</h6>
                                <ul class="nav flex-column custom-footer-list">
                                    <li class="nav-item mb-2"><a href="tel:+523331002476" class="nav-link p-0 text-body-secondary">+52 33 3100 2476</a></li>
                                    <li class="nav-item mb-2"><a href="mailto:contacto@alfonsonunez.net" class="nav-link p-0 text-body-secondary">contacto@alfonsonunez.net</a></li>
                                    <li class="nav-item mb-2"><a href="https://maps.app.goo.gl/BEWgXD5kAjPYnqhv7" target="_blank" class="nav-link p-0 text-body-secondary">Amado Nervo 154
                                        Col. Ladrón de Guevara
                                        Guadalajara, Jalisco
                                        México.
                                    </a></li>
                                </ul>
                            </div>

                        </div>

                        <div class="pt-5 text-center">
                            <small>© 2024 Alfonso Núñez Arquitectos. Powered by <a href="https://www.godspeedmedia.com/" target="_blank">Godspeedmedia</a></small>
                        </div>
                    </footer>

                </div>
            </section>
        </>
    );
}

export default Footer;
