import "../App.css"
import Logo from "../Img/ALFONSO-logo.svg"

function Blog() {
    return (
        <>



            <section className="container pt-3 pb-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto">

                <div class="card mb-5">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-4">
                            <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61ce8a4065c416473ec830ff_bg_casa.jpeg" class="img-blog" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h6 class="card-text">20 enero, 2024</h6>
                                <h2 class="card-title fw-medium mt-3 mb-3">
                                    El objetivo no es la arquitectura, si no lo que permitimos que suceda en ella</h2>
                                <a href="#" class="card-text opacity-50">Leer más</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-4">
                            <img src="https://assets-global.website-files.com/619e933166d45d327c877b79/61ce8847be32b1b5fbef299a_blog3.jpeg" class="img-blog" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h6 class="card-text">20 enero, 2024</h6>
                                <h2 class="card-title fw-medium mt-3 mb-3">
                                Arquitectura: ¿inventar o descubrir?</h2>
                                <a href="#" class="card-text opacity-50">Leer más</a>
                            </div>
                        </div>
                    </div>
                </div>



            </section>
        </>
    );
}

export default Blog;
