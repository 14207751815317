import "../App.css"

function Formulario() {
    return (
        <>
            <form>
                <div class="row">
                    <div class="col">
                        <label for="exampleInputEmail1" class="form-label">Nombre</label>
                        <input type="text" class="form-control" placeholder="" aria-label="First name" />
                    </div>
                    <div class="col">
                        <label for="exampleInputEmail1" class="form-label">Apellido</label>
                        <input type="text" class="form-control" placeholder="" aria-label="Last name" />
                    </div>
                    <div class="mt-3 mb-3">
                        <label for="exampleInputEmail1" class="form-label">Correo electrónico</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Teléfono</label>
                        <input type="tel" class="form-control" id="exampleInputPassword1" />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Mensaje</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Tu mensaje"></textarea>
                    </div>
                    <div class="d-grid gap-2 mt-2">
                        <button type="submit" class="btn btn-outline-dark rounded-0 btn-lg">Enviar</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Formulario;
